<template>
  <div class="footer-box" ref="footerBox">
    <div class="add-jump">
      <div class="external_box">
        <img src="../../static/images/home/external_1.png" alt="" @click="goUrl('https://www.instagram.com/ficclouder')">
        <img src="../../static/images/home/external_2.png" alt="" @click="goUrl('https://www.tiktok.com/@ficcloud')">
        <img src="../../static/images/home/external_3.png" alt="" @click="goUrl('https://twitter.com/ficcloud')">
        <img src="../../static/images/home/external_4.png" alt="" @click="goUrl('https://www.youtube.com/@FICC-novel')">
        <!-- <img src="../../static/images/home/external_5.png" alt="" @click="goUrl('https://www.instagram.com/panda_flipping/')"> -->
      </div>
      <div class="login-box">
        <img src="../../static/images/home/logo_new.png" alt=""><span>Ficcloud.com</span>
      </div>
    </div>
    <div class="go-top" ref="goTop" @click="scrollToTop">
      <i class="el-icon-download"></i>
    </div>
    <div class="footer">© {{ year }} Ficcloud Inc. All rights reserved
      <br><a href="mailto:online@ficcloud.com" class="email-link">E-mail:online@Ficcloud.com</a>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      year: null
    }
  },
  mounted () { 
    var data = new Date()
    this.year = data.getFullYear()
    if(window.screen.availWidth < 1020){
      let screenWidth = window.screen.availWidth
      let scaling = screenWidth / 1020
      this.$refs.footerBox.style.width = `${screenWidth * 2.8}px`
      // this.$refs.goTop.style.right = `-${screenWidth * 1.2}px`
    }
  },
  methods: {
    goUrl(url){
      window.open(url);
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
  }
}
</script>

<style scoped>
.footer-box{
  margin-top: 80px;
}
.add-jump{
  position: relative;
  height: 70px;
}
.external_box{
  display: flex;
  padding: 4px 15px;
  background-color: #eeeeee;
  border-radius: 25px;
  width: 170px;
  position: absolute;
  left: 100px;
}
.external_box>img{
  width: 26px;
  height: 26px;
  margin: 8px;
  cursor: pointer;
}
.login-box{
  display: flex;
  align-items: center;
  margin: 0px auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.login-box>img{
  width: 40px;
  height: auto;
  margin-right: 10px;
}
.login-box>span{
  padding: 0;
  margin: 0;
  line-height: 40px;
  font-size: 16px;
  margin-right: 20px;
  color: #72A464;
}
.footer{
  width: 100%;
  padding: 40px 0px;
  background: #0a0a0a;
  font-size: 12px;
  line-height: 30px;
  color: #FFF;
  text-align: center;
}
.go-top{
  position: fixed;
  bottom: 180px;
  right: 150px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 28px;
  color: #999;
  border-radius: 50%;
  transition: all 0.35s;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
}
.go-top:hover{
  color: #409EFF;
}
.go-top>i{
  transform: rotate(180deg);
}
/* 未访问的链接 */
.email-link:link {
    color: white; /* 白色未访问链接 */
}

/* 访问过的链接 */
.email-link:visited {
    color: rgb(208, 47, 208); /* 紫色访问过的链接 */
}

/* 鼠标点击链接的那一刻 */
.email-link:active {
    color: rgb(127, 212, 66); /* 绿色激活状态的链接 */
}
/*
@media screen and (max-width : 390px) {
  .external_box{
    left: 15px;
  }
  .login-box{
    left: 298px;
    top: 4px ;
  }
  .go-top{
    right: 20px;
  }
  .external_box{
    width: 180px;
  }
  .external_box>img{
    width: 22px;
    height: 22px;
  }
}
*/
</style>